import axios from 'axios';

export default class MyLibraryResource {
  subjects() {
    return axios.get('api/my-library/subjects');
  }

  books(keyword) {
    return axios.get('api/my-library/books?search='+keyword);
  }

  orders() {
    return axios.get('api/my-library');
  }

  deleteOrder(orderId) {
    return axios.delete('api/my-library/' + orderId);
  }

  createOrder(data) {
    return axios.post('api/my-library', data);
  }
}
