<template>
      <div class="container-fluid">
        <div class="row">

            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10 h-100 pb-5">
                <div class="row">
                    <div class="col-12 mb-5">
                        <app-header></app-header>
                        <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                    </div>
                </div>

                <!-- Library Form -->
                <section class=" px-0 mx-0 px-xl-5 mx-xl-5 mb-5">
                    <div class="row justify-content-center">
                        <div class="col-12">

                            <div class="bg-light b-30 shadow-1 p-4 form-container mx-2">
                                <div class="form-header mb-4 mt-2">
                                    <span class="mb-5">{{$t('my-library.order-form')}}</span>
                                </div>

                                <form>
                                    <div class="form-row justify-content-center px-5 p-lg-0">
                                       <!-- Date -->
                                        <div class="col-md-6 col-lg-2 mb-2 mb-sm-0">
                                            <div class="form-row">
                                                <div class="form-group w-100">
                                                    <label for="completion_date" class="f-p2">{{$t('my-library.completion-date')}}</label>
                                                    <div class="form-row">
                                                        <div class="col">
                                                          <b-form-datepicker
                                                            id="completion_date"
                                                            :placeholder="$t('my-library.choose-date')"
                                                            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                                            v-model="orderForm.date">
                                                          </b-form-datepicker>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- subject -->
                                        <div class="col-md-6 col-lg-3 mb-2 mb-sm-0">
                                            <label for="subject" class="f-p2">{{$t('my-library.subject')}}</label>
                                            <multiselect
                                              id="subject"
                                              v-model="orderForm.subject"
                                              :options="subjects.data"
                                              track-by="subjectID"
                                              :label="getSubjectDropdownLabel()"
                                              :placeholder="$t('my-library.choose-subject')"
                                            ></multiselect>
<!--                                            <select class="col mr-sm-2" id="inlineFormCustomSelect">-->
<!--                                                <option selected>{{$t('my-library.choose-subject')}}...</option>-->
<!--                                                <option value="1">One</option>-->
<!--                                                <option value="2">Two</option>-->
<!--                                                <option value="3">Three</option>-->
<!--                                            </select>-->
                                        </div>
                                        <!-- book name -->
                                        <div class="col-md-6 col-lg-3 mb-2 mb-sm-0">
                                            <label for="book" class="f-p2">{{$t('my-library.book')}}</label>
<!--                                            <input type="text" class="form-control" :placeholder="$t('my-library.book-name')">-->
                                            <multiselect
                                              id="book"
                                              v-model="orderForm.book"
                                              :options="books.data"
                                              track-by="bookID"
                                              label="title"
                                              :loading="books.loading"
                                              :options-limit="50"
                                              @search-change="loadBooks"
                                              @input="books.data = []"
                                              :placeholder="$t('my-library.choose-book')"
                                            ></multiselect>
                                        </div>
                                        <!-- lecture -->
                                        <div class="col-md-3 col-lg-1 mb-2 mb-sm-0">
                                            <label for="lecture">{{$t('my-library.lecture')}}</label>
                                            <input id="lecture" v-model="orderForm.lecture" type="text" class="form-control" placeholder="4">
                                        </div>
                                        <!-- pages -->
                                        <div class="col-md-3 col-lg-2">
                                            <label for="start_page">{{$t('my-library.pages')}}</label>
                                            <div class="form-row">
                                                <div class="col">
                                                    <input id="start_page" v-model="orderForm.start_page" type="text" class="form-control" placeholder="34">
                                                </div>
                                                <div class="col">
                                                    <input id="page_end" v-model="orderForm.end_page" type="text" class="form-control" placeholder="63">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- info -->
                                    <div class="col-12 col-lg-6 mt-4">
                                        <div class="alert info">
                                            <span class="text-blue">{{$t('my-library.initial-final-pages')}}</span>
                                        </div>
                                    </div>

                                    <div class="text-center">
                                        <button role="button" @click="createOrder" class="btn btn-primary-red-10 mt-4">{{$t('my-library.order')}}</button>
                                    </div>

                                </form>

                            </div>
                        </div>

                    </div>

                </section>
                <!-- past orders -->
                <section class=" px-0 mx-0 px-xl-5 mx-xl-5 mb-5 pt-5 orders">
                  <div class="row">
                    <!--order status -->
                    <div class="col-12 mt-4 mb-5 pt-1 px-4">
                      <div class="d-inline-block bg-white-88 border-round py-md-0 py-lg-2 px-3 px-sm-4">
                        <div class="d-flex align-items-center justify-content-between py-3 py-sm-2 px-0 px-sm-3 mx-1">
                          <div class="d-flex align-items-center mr-4 pr-2">
                            <img src="/static/images/icons/evaluations/recover.png" alt="recover evaluation" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                            <span class="s-b-1">{{ $t('my-library.order-sent') }}</span>
                          </div>
                          <div class="d-flex align-items-center mr-4 pr-2">
                            <img src="/static/images/icons/evaluations/accept.png" alt="accepted evaluation" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                            <span class="s-b-1">{{ $t('my-library.order-ready') }}</span>
                          </div>
                          <div class="d-flex align-items-center mr-4 pr-2"  data-toggle="tooltip" data-placement="top" :title="$t('my-library.order-cancel-option')">
                            <img src="/static/images/icons/evaluations/fail.png" alt="failed evaluation" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                            <span class="s-b-1">{{ $t('my-library.order-canceled') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                      <div class="col-12">
                          <div class="bg-light b-30 shadow-1 p-4 form-container mx-2">
                              <div class="form-header mt-3">
                                  {{$t('my-library.past-orders')}}
                              </div>
                               <div class="col-12 col-lg-8 mt-4 mb-5">
                                  <div class="alert info">
                                      <span class="text-blue">{{$t('my-library.order-number')}}</span>
                                  </div>
                              </div>

                              <div class="col-12">
                                <sk-list v-if="orders.loading" :height="0.03" :items-count="4"></sk-list>
                                <table v-else class="table-blue-wide w-100">
                                      <thead>
                                          <tr>
                                              <th>N</th>
                                              <th class="pl-2" >{{$t('my-library.book')}}</th>
                                              <th class="text-center">{{$t('my-library.pages')}}</th>
                                              <th class="text-center">{{$t('my-library.price')}}</th>
                                              <th class="text-center">{{$t('my-library.completion-date')}}</th>
                                              <th class="text-center">{{$t('my-library.status')}}</th>
                                              <th style="min-width:3rem">{{$t('my-library.delete')}}</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="order of orders.data">
                                              <td data-label="N">{{ order.orderID }}</td>
                                              <td :data-label="$t('my-library.book')" class="pl-2"  style="max-width: 39rem;">
                                                {{ order.book.title }}
                                              </td>
                                              <td :data-label="$t('my-library.pages')" class="text-center">
                                                {{ order.page_start }} - {{ order.page_end }}
                                              </td>
                                              <td :data-label="$t('my-library.price')" class="text-center">{{ order.gel }} {{ $t('my-library.gel') }}</td>
                                              <td :data-label="$t('my-library.completion-date')" class="text-center">{{ order.date }}</td>
                                              <td :data-label="$t('my-library.status')" class="text-center">
                                                  <img :src="orderStatusIcon(order)" alt="completed" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                                              </td>
                                              <td class="text-center">
                                                  <img
                                                    @click="deleteOrder(order)"
                                                    v-if="order.can_delete == 1"
                                                    src="/static/images/icons/evaluations/fail-ic.png"
                                                    class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic cursor-pointer">
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
                </section>
            </main>
        </div>
    </div>
</template>
<script>

import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import MyLibraryResource from '../api/myLibraryResource';
import Multiselect from 'vue-multiselect';
import SkList from '../components/skeletons/sk-list';

const myLibraryResource = new MyLibraryResource();

export default {
  name:'my-library',
  components: { SkList, sidebar, appHeader, pageHeader, Multiselect },
  computed: {
      pageData() {
        return {
            title: this.$t('my-library.title'),
            breadcrumb: [{
              icon: '',
              title: this.$t('home.title'),
              link: '/',
              isActive: false,
            },
            {
              icon: '',
              title: this.$t('my-library.title'),
              link: '/my-library',
              isActive: true,
              }],
        };
      },
  },

  data() {
    return {
      subjects: {
        loading: false,
        data: []
      },
      orders: {
        loading: false,
        data: []
      },
      books: {
        loading: false,
        keyword: '',
        data: []
      },
      orderForm: {}
    }
  },

  created() {
    this.loadSubjects();
    this.loadOrders();

    this.orderForm = this.emptyOrderForm();
  },

  methods: {
    deleteOrder(order) {
      this.orders.loading = true;
      myLibraryResource.deleteOrder(order.orderID)
      .then(response => {
        this.helpers.notifySuccessMessage(this.$t('my-library.order-successfully-deleted'))
        this.loadOrders();
      })
      .catch(error => {
        this.helpers.notifyErrorMessage(error)
      })
      .finally(() => {
        this.orders.loading = false;
      })
    },

    orderStatusIcon(order) {
      if(order.ready === 'გაუქმებულია') return '/static/images/icons/evaluations/fail.png';

      if(order.ready === 'გაგზავნილია') return '/static/images/icons/evaluations/recover.png';

      return '/static/images/icons/evaluations/accept.png';
    },

    getSubjectDropdownLabel() {
      return this.$i18n.locale === 'ka' ? 'subject' : 'subjecteng';
    },

    loadSubjects() {
      this.subjects.loading = true;
      myLibraryResource.subjects()
      .then(response => {
        this.subjects.data = response.data.data;
      })
      .finally(() => {
        this.subjects.loading = false;
      })
    },

    loadOrders() {
      this.orders.loading = true;
      myLibraryResource.orders()
        .then(response => {
          this.orders.data = response.data.data;
        })
        .finally(() => {
          this.orders.loading = false;
        })
    },

    loadBooks(keyword) {
      this.books.loading = true;
      myLibraryResource.books(keyword)
        .then(response => {
          this.books.data = response.data.data;
        })
        .finally(() => {
          this.books.loading = false;
        })
    },

    createOrder(e) {
      e.preventDefault();
      const data = Object.assign({}, this.orderForm);

      data.subject_id = data.subject.subjectID;
      data.book_id = data.book.bookID;
      delete data.subject;
      delete data.book;

      this.orders.loading = true;
      myLibraryResource.createOrder(data)
      .then(response => {
        this.orderForm = this.emptyOrderForm();

        this.helpers.notifySuccessMessage(this.$t('my-library.order-successfully-created'));
        this.loadOrders();
      })
      .catch(error => {
        this.helpers.notifyErrorMessage(error);
      })
      .finally(() => {
        this.orders.loading = false;
      })
    },

    emptyOrderForm() {
      return {
        subject: '',
        book: '',
        start_page: '',
        end_page: '',
        date: '',
        lecture: '',
      }
    },
  }
}
</script>
<style scoped>
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
  @import '~bootstrap-vue/dist/bootstrap-vue.min.css';

@media screen and (max-width:576px){
    td{ min-height: 43px;}
}
</style>
